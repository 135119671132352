/** @jsx jsx */
import { jsx } from "theme-ui";
import { Helmet } from "react-helmet";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import useSiteMetadata from "./SiteMetadata";
import { withPrefix, Link } from "gatsby";
import CookieConsent from "react-cookie-consent";

const TemplateWrapper = ({ children }) => {
  const { title, description } = useSiteMetadata();

  return (
    <div>
      <Helmet>
        <html lang="de" />
        <title>{title}</title>
        <meta name="description" content={description} />

        <meta name="theme-color" content="#fff" />

        <meta property="og:type" content="business.business" />
        <meta property="og:title" content={title} />
        <meta property="og:url" content="/" />
        <meta
          property="og:image"
          content={`${withPrefix("/")}img/foto-schreibtisch-02-04-20.jpeg`}
        />
      </Helmet>
      <Navbar />
      <div sx={{position:"relative", top: 5}}>{children}</div>
      <Footer />
      <CookieConsent
        location="bottom"
        buttonText="Akzeptieren"
        cookieName="gatsby-gdpr-google-tagmanager"
        buttonStyle={{
          backgroundColor: "white",
          padding: "0.5rem",
          borderRadius: "5px"
        }}
        style={{
          bg: "black",
          padding: "0.5rem"
        }}
        expires={730}
      >
        Diese Webseite nutzt Cookies zur Bereitstellung der Inhalte.{" "}
        <Link
          sx={{ textDecoration: "underline", color: "background" }}
          to="/datenschutzerklärung"
        >
          Datenschutz.
        </Link>
      </CookieConsent>
    </div>
  );
};

export default TemplateWrapper;
