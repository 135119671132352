/** @jsx jsx */
import { jsx } from "theme-ui";
import { Link } from "gatsby";
import Logo from "../../static/img/logo.svg";
import Termin from "../../static/img/termin.svg";
import { Styled } from "theme-ui";

const Navbar = ({ props }) => {
	return (
		<header
			sx={{
				position: "fixed",
				top: "0px",
				zIndex: "3",
				width: "100%",
				height: 3,
				bg: "background",
			}}
		>
			<Link
				to="/#agentur-ignis-start"
				sx={{ display: "block", float: "left", maxHeight: 3 }}
			>
				<Logo
					sx={{
						height: 3,
						py: 2,
						ml: 2,
					}}
					title="Agentur ignis"
					alt="Agentur ignis, Webdesign für Architekturbüros"
				/>
			</Link>
			<input
				type="checkbox"
				id="menu-btn"
				sx={{
					display: "none",
					":checked ~ ul": {
						maxHeight: "400px",
					},
					":checked ~ label": {
						justifyContent: "center",
					},
					":checked ~ label span:nth-of-type(1)": {
						transform: "rotate(45deg)",
						position: "relative",
						top: "3px",
					},
					":checked ~ label span:nth-of-type(2)": {
						bg: "transparent",
					},
					":checked ~ label span:nth-of-type(3)": {
						transform: "rotate(-45deg)",
						position: "relative",
						top: "-3px",
					},
				}}
			/>
			<label
				htmlFor="menu-btn"
				sx={{
					cursor: "pointer",
					float: "right",
					py: 3,
					pl: "0px",
					pr: 2,
					height: 3,
					userSelect: "none",
					display: ["flex", "flex", "none"],
					flexDirection: "column",
					justifyContent: "space-around",
				}}
			>
				<span
					sx={{
						bg: "text",
						display: "block",
						height: "3px",
						width: 1,
						transition: "all 0.2s ease-out",
					}}
				></span>
				<span
					sx={{
						bg: "text",
						display: "block",
						height: "3px",
						width: 1,
						transition: "all 0.2s ease-out",
					}}
				></span>
				<span
					sx={{
						bg: "text",
						display: "block",
						height: "3px",
						width: 1,
						transition: "all 0.2s ease-out",
					}}
				></span>
			</label>
			<Styled.a
				as={Link}
				to="/termin"
				sx={{
					display: ["flex", "flex", "none"],
					alignItems: "center",
					float: "right",
					height: [1, 1, 2],
					px: "0px",
					ml: "0px",
					mr: 2,
					my: [3, 3, 3],
					border: (theme) => `2px solid ${theme.colors.primary}`,
					borderRadius: "100px",
				}}
			>
				<Termin
					sx={{
						height: [1, 1, 2],
						position: "relative",
						right: "1px",
					}}
					title="Termin vereinbaren"
					alt="Termin vereinbaren"
				/>
				<span
					sx={{
						textAlign: "center",
						pr: 2,
						pl: 1,
						fontSize: [2, 2, 3],
					}}
				>
					Termin
				</span>
			</Styled.a>
			<ul
				sx={{
					clear: ["both", "both", "none"],
					maxHeight: ["0px", "0px", "none"],
					transition: "max-height .2s ease-out",
					listStyle: "none",
					overflow: "hidden",
					height: [null, null, "100%"],
					display: [null, null, "flex"],
					justifyContent: [null, null, "flex-end"],
					alignItems: [null, null, "center"],
					padding: "0px",
					margin: "0px",
					bg: "background",
					"li:nth-of-type(2)": {
						borderTop: [
							"1px solid lightgrey",
							"1px solid lightgrey",
							"none",
						],
					},
					li: {
						borderBottom: [
							"1px solid lightgrey",
							"1px solid lightgrey",
							"none",
						],
						a: {
							display: "block",
							fontSize: [2, 2, 3],
							m: [1, 1, "0px"],
							mx: [null, null, 2],
							textAlign:"center"
							
						},
					},
					"li:first-of-type > a": {
						display: "flex",
					}
				}}
			>
				<li
					sx={{
						display: ["none", "none", "flex"],
					}}
				>
					<Styled.a
						as={Link}
						to="/termin"
						sx={{
							display: ["none", "none", "flex"],
							alignItems: "center",
							float: "left",
							height: 2,
							border: (theme) =>
								`2px solid ${theme.colors.primary}`,
							borderRadius: "100px",
						}}
					>
						<Termin
							sx={{
								height: 2,
								position: "relative",
								right: "1px",
							}}
							title="Termin vereinbaren"
							alt="Termin vereinbaren"
						/>
						<span sx={{ textAlign: "center", px: 3 }}>
							Termin
						</span>
					</Styled.a>
				</li>
				<li>
					<Styled.a
						onClick={() => {
							document.getElementById("menu-btn").checked = false;
						}}
						onKeyDown={() => {
							document.getElementById("menu-btn").checked = false;
						}}
						as={Link}
						to="/#agentur-ignis-start"
					>
						Start
					</Styled.a>
				</li>
				<li>
					<Styled.a
						onClick={() => {
							document.getElementById("menu-btn").checked = false;
						}}
						onKeyDown={() => {
							document.getElementById("menu-btn").checked = false;
						}}
						as={Link}
						to="/#vorteile"
					>
						Vorteile
					</Styled.a>
				</li>
				<li>
					<Styled.a
						onClick={() => {
							document.getElementById("menu-btn").checked = false;
						}}
						onKeyDown={() => {
							document.getElementById("menu-btn").checked = false;
						}}
						as={Link}
						to="/#leistung"
					>
						Leistung
					</Styled.a>
				</li>
				<li>
					<Styled.a
						onClick={() => {
							document.getElementById("menu-btn").checked = false;
						}}
						onKeyDown={() => {
							document.getElementById("menu-btn").checked = false;
						}}
						as={Link}
						to="/#team"
					>
						Team
					</Styled.a>
				</li>
			</ul>
		</header>
	);
};

export default Navbar;
