/** @jsx jsx */
import { jsx, Styled } from "theme-ui";
import { Link } from "gatsby";
import Logo from "../../static/img/ignis-logo-final-schwarz.svg";

const Footer = () => {
	return (
		<footer
			sx={{
				bg: "muted",
				display: "flex",
				justifyContent: "center",
				py: 4,
				position: "relative",
				top: 5,
			}}
		>
			<div
				sx={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
				}}
			>
				<Logo
					sx={{ width: 8, mb: 2 }}
					title="Agentur ignis Logo"
					alt="Agentur ignis Logo"
				/>
				<div sx={{ display: "flex", width:"100%", justifyContent: "space-between" }}>
					<Styled.a
						sx={{ fontSize: 3, px: "0px", mx: "0px" }}
						as={Link}
						to="/impressum"
					>
						Impressum
					</Styled.a>
					<Styled.a
						sx={{ fontSize: 3, px: "0px", mx:"0px" }}
						as={Link}
						to="/datenschutz"
					>
						Datenschutz
					</Styled.a>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
